<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="100%"
      max-width="500"
      overlay-color="#000000"
      overlay-opacity="0.8"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pt-16 pb-0 text-h5 font-weight-bold text-center"
            >
              간병인 요청 등록 완료!
            </v-col>
            <v-col
              cols="12"
              class="py-12 text-center"
            >
              요청완료에 따른 안내사항이<br>
              카카오톡으로 발송되었으니 확인 부탁드립니다.
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions
          class="bottom-card-action ma-0 pa-0"
        >
          <v-btn
            block
            :plain="false"
            height="60"
            class="elevation-0 white--text btn-success"
            color="#007AFF"
            @click="dialog=false; $router.push('/').catch(() => {})"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  created() {
    const self = this
    this.$bus.$on('showSuccessDialog', () => {
      self.dialog = true
    })
    // self.dialog = true // for testing
  },
  methods: {
    clodeAndRedirect() {
      this.dialog = false
      this.$nextTick(() => {
        this.$router.push('/').catch(() => {})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 10px !important;
}
.bottom-card-action {
  height: 60px !important;
  background: #007AFF;
}
.btn-success {
  padding: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
</style>
